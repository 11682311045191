/* pages/Index.svelte generated by Svelte v3.58.0 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	space
} from "svelte/internal";

import moims from '~/stores/moims';
import MoimUrl from './MoimUrl.svelte';

function create_fragment(ctx) {
	let div3;
	let div1;
	let h1;
	let t1;
	let p0;
	let t3;
	let p1;
	let t5;
	let div0;
	let button;
	let t7;
	let div2;
	let mounted;
	let dispose;

	return {
		c() {
			div3 = element("div");
			div1 = element("div");
			h1 = element("h1");
			h1.textContent = "후잉모임";
			t1 = space();
			p0 = element("p");
			p0.textContent = "지속적이고 느슨한 N빵 관리";
			t3 = space();
			p1 = element("p");
			p1.textContent = "둘이 만나도, 여럿이 만나도, 중간에 누가 빠져도, 최대한 공평하게\n\t\t\t결제하세요.";
			t5 = space();
			div0 = element("div");
			button = element("button");
			button.textContent = "모임 생성";
			t7 = space();
			div2 = element("div");
			div2.innerHTML = `powered by <a href="https://whooing.com" target="_blank" style="color:#333">whooing.com</a>`;
			attr(h1, "class", "mb-4");
			attr(p0, "class", "opacity-75");
			attr(p1, "class", "opacity-75");
			attr(button, "class", "btn btn-primary btn-lg");
			attr(div0, "class", "mt-4 mb-5");
			attr(div1, "class", "py-5");
			attr(div2, "class", "opacity-50");
			attr(div3, "class", "text-center");
		},
		m(target, anchor) {
			insert(target, div3, anchor);
			append(div3, div1);
			append(div1, h1);
			append(div1, t1);
			append(div1, p0);
			append(div1, t3);
			append(div1, p1);
			append(div1, t5);
			append(div1, div0);
			append(div0, button);
			append(div3, t7);
			append(div3, div2);

			if (!mounted) {
				dispose = listen(button, "click", /*handleCreate*/ ctx[0]);
				mounted = true;
			}
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div3);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self) {
	const handleCreate = () => {
		window.modal.prompt('생성할 모임의 이름을 입력해주세요', '', v => {
			moims.create(v, slug => {
				window.modal.open({
					title: '생성 완료',
					size: 'xs',
					content: MoimUrl,
					okLabel: '모임으로 이동',
					cancelLabel: '',
					callback: () => {
						location.replace(`/s/${slug}`);
					},
					props: { slug }
				});
			});
		});
	};

	return [handleCreate];
}

class Index extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Index;